import React, { useEffect, useRef, useState } from "react";
import { getTheme } from "../functions/theme";
import HomeOurServices from "./home-components/home-our-services";
import HomeRecentWorks from "./home-components/home-recent-works";
import HomeSubtitle from "./home-components/home-subtitle";
import HomeTitle from "./home-components/home-title";
import DefaultAppView from "../app-view/default-app-view";
import { getUserPreferredLocale } from "../functions/utils";
import { useSiteHomeI18N_IT } from "../hooks/site-strapi-i18n-IT/site-home-i18n-IT";
import { useSiteHomeI18N_EN } from "../hooks/site-strapi-i18n-EN/site-home-i18n-EN";

// HOME
const HomePage = () => {

  const targetRef: React.MutableRefObject<undefined> = useRef();
  const [targetElement, setElement] = useState();

  const currentTheme: 'light' | 'dark' = getTheme();
  const _userLocale: string = getUserPreferredLocale();

  const strapiI18NContent: any | undefined = (_userLocale.includes('it') || _userLocale.includes('IT')) ? useSiteHomeI18N_IT() : useSiteHomeI18N_EN();
  // HOME FIRST SECTION (THE COMPANY) I18N CONTENT
  const homeSubtitleContent: any | undefined = {
    title: strapiI18NContent.sectionTitle01,
    description: strapiI18NContent.section01
  }
  // HOME SECOND SECTION (PROJECTS) I18N CONTENT
  const homeRecentWorksContent: any | undefined = {
    title: strapiI18NContent.sectionTitle02,
    description: strapiI18NContent.section02
  }
  // HOME THIRD SECTION (EXPERTISE) I18N CONTENT
  const homeOurServices: any | undefined = {
    title: strapiI18NContent.sectionTitle03,
    description: strapiI18NContent.section03,
    service01: {
      serviceTitle: strapiI18NContent.service01,
      serviceDescription: strapiI18NContent.serviceDescription01,
      listo: strapiI18NContent.serviceListo01.split(',')
    },
    service02: {
      serviceTitle: strapiI18NContent.service02,
      serviceDescription: strapiI18NContent.serviceDescription02,
      listo: strapiI18NContent.serviceListo02.split(',')
    },
    service03: {
      serviceTitle: strapiI18NContent.service03,
      serviceDescription: strapiI18NContent.serviceDescription03,
      listo: strapiI18NContent.serviceListo03.split(',')
    }
  }

  // Hook w/ update of target (Our Services) ref 
  useEffect(() => {
    setElement(targetRef.current);
  }, []);
  // ---
  
  return (
    <DefaultAppView currentUrl="/" userLocale={_userLocale}>
      
      <div className="relative z-0 reverse-bg-zone-trigger dark:bg-black dark:text-cardinal-white" id="reverse-bg-zone-trigger">
        <HomeTitle currentTheme={currentTheme} userLocale={_userLocale}/>
        <HomeSubtitle currentTheme={currentTheme} userLocale={_userLocale} textContent={homeSubtitleContent}/>
      </div>
      {/* --- */}

      {/* --- Container for reverse color in Light Mode | WHITE to BLACK - */}
      <div className="bg-black reverse-color-zone-trigger text-cardinal-white dark-bg-zone" id="reverse-color-zone-trigger">
        <div className="py-20"/>
        <HomeRecentWorks textContent={homeRecentWorksContent}/>
        <div className="py-20"/>
        <HomeOurServices targetRef={targetRef} textContent={homeOurServices}/>
      </div>
      {/* --- */}
      
    </DefaultAppView>
  );
}

export default HomePage;