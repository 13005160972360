import { graphql, useStaticQuery } from "gatsby"

export const useSiteHomeI18N_IT = () => {
  const data: any = useStaticQuery(graphql`
    query {
      strapiHome {
        localizations {
          data {
            attributes {
              sectionTitle01
              section01
              sectionTitle02
              section02
              sectionTitle03
              section03
              service01
              serviceDescription01
              serviceListo01
              service02
              serviceDescription02
              serviceListo02
              service03
              serviceDescription03
              serviceListo03
            }
          }
        }
      }
    }
  `)

  return data.strapiHome.localizations.data[0].attributes;

}