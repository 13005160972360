import React, { useEffect, useState } from "react";
import DefaultAppView from "../app-view/default-app-view";
import { SEO } from "../components/seo";
import { checkTheme } from "../functions/theme";
import { isMobileDevice } from "../functions/utils";
import HomePage from "./home";
import { StaticQueryDocument, graphql, useStaticQuery } from "gatsby";
import { SEOData } from "../constants/types";

// INDEX
const IndexPage = () => {

  // --- STATE for Hydration 
  const [domLoaded, setDomLoaded] = useState<boolean>(false);
  // ---

  // --- Hook w/ hydration, theme and mobile checks
  useEffect(() => {

    // For Hydration
    setDomLoaded(true);

    // Da richiamare quindi portare fuori da useEffect
    checkTheme();

    // Cosa Mistica senza prove del suo funzionamento
    const isMobile: boolean = isMobileDevice();

    // Copiato da freename
    if (isMobile) {
      const vh: number = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', vh + 'px');
    }
    // Fine cosa Mistica senza prove del suo funzionamento
  }, []);
  // ---

  return (
    <>
      { domLoaded && 
        <HomePage/>
      }
    </>
  )

}

export default IndexPage;

// ----------------------- SEO CREATION ---------------------- //

export const seoData: StaticQueryDocument | undefined = graphql`
  query {
    strapiHome {
      SEO {
          metaTitle
          metaDescription
          keywords
          preventIndexing
          pathname
      }
    } 
  }
`

export const Head = () => {
  const seo: SEOData = seoData && useStaticQuery(seoData)?.strapiHome?.SEO;

  return (
      <SEO 
        title={seo?.metaTitle} 
        description={seo?.metaDescription} 
        keywords={seo?.keywords} 
        preventIndexing={seo?.preventIndexing} 
        pathname={seo?.pathname}/>   
  )
}
