import React, { useEffect, useState } from "react";
import HomeSecondaryTitle from "./home-secondary-title";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { graphql, Link, StaticQueryDocument, useStaticQuery } from "gatsby";

gsap.registerPlugin(ScrollTrigger);

// HOME COMPONENT - Recent works display component
const HomeRecentWorks = (props: {
  textContent: any | undefined
}) => {

  // --- Getting dataws from query 
  const staticQuery: any | undefined = data && useStaticQuery(data);

  const _projects: any[] = staticQuery && staticQuery.allStrapiProject.edges.map((el: any) => {
    return {
      nodeImage: el.node.picture.localFile,
      priority: el.node.priority,
      placeholder: el.node.title,
      id: el.node.id,
      link: el.node.link
    };
  }).sort((a: any, b: any) => a.priority && b.priority && (a.priority - b.priority));

  // --- STATES
  const [projects, setProjects] = useState<any[]>(_projects);

  
  // Hook w/ slider logic 
  useEffect(() => {

    const slider: any = document.getElementById('custom-container');
    let mouseDown: boolean = false;
    let startX: number = 0;
    let scrollLeft: number = 0;

    const startDragging = (e: any) => {

      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;

    };

    const stopDragging = (event: any) => {

      mouseDown = false;

    };

    slider.addEventListener('mousemove', (e: any) => {

      e.preventDefault();
      if (!mouseDown) { return; }
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;

    });

    // Add the event listeners
    slider.addEventListener('mousedown', startDragging, false);
    slider.addEventListener('mouseup', stopDragging, false);
    slider.addEventListener('mouseleave', stopDragging, false);
    
  }, [])
  // ---

  return (
    <div className="flex flex-col h-screen px-4 lg:px-32 md:px-16">
      <div className="my-20">
        <HomeSecondaryTitle 
          section={2}
          smallTitle={props?.textContent && props.textContent.title} 
          mainContent={props?.textContent && props.textContent.description}/>
      </div>
      <div className="flex items-stretch overflow-x-scroll overflow-y-hidden" id="custom-container">
        <div className="flex flex-row justify-center mb-5 min-w-min ">
          { projects &&
            projects.map((image: any) => {
              const _image: IGatsbyImageData = image?.nodeImage && getImage(image.nodeImage);
              const _link: string = image.placeholder.toLowerCase();
              return (
                image &&
                <div className="h-full mr-5 btn-like panel img-container-width" key={image.id}>
                  <Link to={`/project-detail/${_link}`}> 
                    <GatsbyImage image={_image} alt={'test'} className="w-full h-full"/>
                  </Link> 
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default HomeRecentWorks;

// --- Graphql query getting Home datas from strapi 
export const data: StaticQueryDocument | undefined = graphql`
  query {
    allStrapiProject {
      edges {
        node {
          id
          title
          link
          priority
          picture {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
// ---